import React from 'react';
import { BackgroundPattern } from './components/BackgroundPattern';
import { LinkCard } from './components/LinkCard';
import { links } from './data/links';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-950 via-purple-900 to-blue-900 text-white">
      <BackgroundPattern />

      <div className="relative min-h-screen flex flex-col items-center justify-center p-8">
        <h1 className="text-6xl font-bold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
          Pattern in Chaos
        </h1>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 max-w-6xl w-full">
          {links.map((link, index) => (
            <LinkCard key={index} link={link} />
          ))}
        </div>

        <footer className="absolute bottom-8 text-sm text-gray-400">
          © {new Date().getFullYear()} Pattern in Chaos. All rights reserved.
        </footer>
      </div>
    </div>
  );
}

export default App;