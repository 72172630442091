import React from 'react';
import { ExternalLink } from 'lucide-react';
import { LinkType } from '../types';

interface LinkCardProps {
  link: LinkType;
}

export function LinkCard({ link }: LinkCardProps) {
  return (
    <a
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      className="group relative overflow-hidden rounded-xl bg-white/10 p-6 backdrop-blur-sm transition-all duration-300 hover:bg-white/20 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/20"
    >
      <div className="flex items-center gap-4">
        <div className="rounded-lg bg-gradient-to-br from-blue-500 to-purple-500 p-3">
          {link.icon}
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">{link.title}</h2>
          <p className="text-sm text-gray-300">{link.description}</p>
        </div>
        <ExternalLink className="ml-auto opacity-0 group-hover:opacity-100 transition-opacity" size={20} />
      </div>
    </a>
  );
}