import React from 'react';
import { Hexagon, Circle, Triangle, Square } from 'lucide-react';

export function BackgroundPattern() {
  return (
    <div className="absolute inset-0 overflow-hidden opacity-10">
      {[...Array(20)].map((_, i) => (
        <div
          key={i}
          className="absolute animate-pulse"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `pulse ${2 + Math.random() * 3}s infinite`,
            animationDelay: `${Math.random() * 2}s`
          }}
        >
          {i % 4 === 0 && <Hexagon size={40} />}
          {i % 4 === 1 && <Circle size={30} />}
          {i % 4 === 2 && <Triangle size={35} />}
          {i % 4 === 3 && <Square size={25} />}
        </div>
      ))}
    </div>
  );
}