import React from 'react';
import { Hexagon, Circle, Triangle, Square } from 'lucide-react';
import { LinkType } from '../types';

export const links: LinkType[] = [
  {
    title: "Digital Garden",
    description: "A collection of thoughts, ideas, and experiments",
    url: "https://example.com/garden",
    icon: <Hexagon className="w-6 h-6" />,
  },
  {
    title: "Art Gallery",
    description: "Curated collection of digital artwork",
    url: "https://example.com/gallery",
    icon: <Circle className="w-6 h-6" />,
  },
  {
    title: "Tech Hub",
    description: "Latest in technology and innovation",
    url: "https://example.com/tech",
    icon: <Triangle className="w-6 h-6" />,
  },
  {
    title: "Music Space",
    description: "Explore sound and rhythm",
    url: "https://example.com/music",
    icon: <Square className="w-6 h-6" />,
  },
  {
    title: "Design Lab",
    description: "Where creativity meets function",
    url: "https://example.com/design",
    icon: <Hexagon className="w-6 h-6" />,
  },
  {
    title: "Code Vault",
    description: "Programming resources and snippets",
    url: "https://example.com/code",
    icon: <Triangle className="w-6 h-6" />,
  }
];